<header>

  <div class="navbar navbar-dark bg-dark box-shadow">
    <div class="container d-flex justify-content-between">
      <a href="#" class="navbar-brand d-flex align-items-center">
        <img src="./assets/img/RTAM_logo.png" width="50"/>
        <strong>&nbsp;Range Manager's Toolkit</strong>
      </a>
      <a class="btn btn-primary" style="background-color: #c61118!important;" href="https://fedstrat.atlassian.net/jira/software/projects/RP/boards/5" role="button"
        ><i class="fab fa-atlassian"></i>&nbsp;JIRA</a>
    </div>
  </div>
</header>

<main role="main">

  <section class="jumbotron text-center">
    <div class="container">
      <h1 class="jumbotron-heading">Development Tools</h1>
      <p class="lead text-muted">Quick links to helpful development and security tools</p>
    </div>
  </section>

    <div class="container">

      <div class="row">
        <div class="col-md-3">
          <mdb-card>
            <!--Card image-->
            <!-- <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg" alt="Card image cap"></mdb-card-img> -->
            <!--Card content-->
            <mdb-card-body>

              <!--Title-->
              <mdb-card-title>
                <h4>Gitlab</h4>
              </mdb-card-title>

              <!--Text-->
              <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                content.
              </mdb-card-text>

              <a href="http://gitlab.fed-strat.cloud" mdbBtn color="primary" mdbWavesEffect>View</a>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div class="col-md-3">
          <mdb-card>
            <!--Card image-->
            <!-- <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg" alt="Card image cap"></mdb-card-img> -->
            <!--Card content-->
            <mdb-card-body>

              <!--Title-->
              <mdb-card-title>
                <h4>Nuget</h4>
              </mdb-card-title>

              <!--Text-->
              <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                content.
              </mdb-card-text>

              <a href="http://nuget.fed-strat.cloud" mdbBtn color="primary" mdbWavesEffect>View</a>
            </mdb-card-body>
          </mdb-card>
        </div>
        <div class="col-md-3">
          <mdb-card>
            <!--Card image-->
            <!-- <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg" alt="Card image cap"></mdb-card-img> -->
            <!--Card content-->
            <mdb-card-body>

              <!--Title-->
              <mdb-card-title>
                <h4>Jenkins</h4>
              </mdb-card-title>

              <!--Text-->
              <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                content.
              </mdb-card-text>

              <a href="http://jenkins.fed-strat.cloud" mdbBtn color="primary" mdbWavesEffect>View</a>
            </mdb-card-body>
          </mdb-card>
        </div>

        <div class="col-md-3">
          <mdb-card>
            <!--Card image-->
            <!-- <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg" alt="Card image cap"></mdb-card-img> -->
            <!--Card content-->
            <mdb-card-body>

              <!--Title-->
              <mdb-card-title>
                <h4>SonarQube</h4>
              </mdb-card-title>

              <!--Text-->
              <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                content.
              </mdb-card-text>

              <a href="http://sonar.fed-strat.cloud" mdbBtn color="primary" mdbWavesEffect>View</a>
            </mdb-card-body>
          </mdb-card>
        </div>

        <div class="col-md-3">
          <mdb-card style="margin-top: 15px;">
            <!--Card image-->
            <!-- <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg" alt="Card image cap"></mdb-card-img> -->
            <!--Card content-->
            <mdb-card-body>

              <!--Title-->
              <mdb-card-title>
                <h4>Web Map Viewer</h4>
              </mdb-card-title>

              <!--Text-->
              <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                content.
              </mdb-card-text>

              <a href="http://wmv.fed-strat.cloud" mdbBtn color="primary" mdbWavesEffect>View</a>
            </mdb-card-body>
          </mdb-card>
        </div>


        <div class="col-md-3">
          <mdb-card style="margin-top: 15px;">
            <!--Card image-->
            <!-- <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg" alt="Card image cap"></mdb-card-img> -->
            <!--Card content-->
            <mdb-card-body>

              <!--Title-->
              <mdb-card-title>
                <h4>MCRTAMS Mock Services</h4>
              </mdb-card-title>

              <!--Text-->
              <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                content.
              </mdb-card-text>

              <a href="http://mcrtams.fed-strat.cloud" mdbBtn color="primary" mdbWavesEffect>View</a>
            </mdb-card-body>
          </mdb-card>
        </div>

        <div class="col-md-4">
          <mdb-card style="margin-top: 15px;">
            <!--Card image-->
            <!-- <mdb-card-img src="https://mdbootstrap.com/img/Photos/Lightbox/Thumbnail/img%20(97).jpg" alt="Card image cap"></mdb-card-img> -->
            <!--Card content-->
            <mdb-card-body>

              <!--Title-->
              <mdb-card-title>
                <h4>ArcGIS Server</h4>
              </mdb-card-title>

              <!--Text-->
              <mdb-card-text> Some quick example text to build on the card title and make up the bulk of the card's
                content.
              </mdb-card-text>

              <a href="https://maps.fed-strat.cloud/arcgis/manager/" mdbBtn color="primary" mdbWavesEffect>View</a>
            </mdb-card-body>
          </mdb-card>
        </div>
      </div>
    </div>


</main>
